import classNames from "classnames";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { FormattedMeasurement } from "../localization";
import { DeviceContext } from "../state/DeviceContext";
import { UserContext } from "../state/UserContext";
import {
  calculateGaugePadding,
  isFreezingTemp,
} from "../utility/measurement-helpers";

const Gauge = ({
  className,
  value,
  big,
  measuredVariable,
  lastMeasurement,
  deviceType,
}) => {
  const { scoutMinMax } = useContext(DeviceContext);
  const { currentUser } = useContext(UserContext);

  const imperial = currentUser.pref_unit_temp === "fahrenheit";

  let min = undefined;
  let max = undefined;

  switch (measuredVariable) {
    case "voltage_battery":
      min = 0;
      max = 4;
      break;
    case "voltage_external":
      min = 0;
      max = 12;
      break;
    case "moisture":
      min = 0;
      max = scoutMinMax["moisture"].max * 100;
      break;
    case "salinity":
      min = 0;
      max = scoutMinMax["salinity"].max;
      break;
    case "temperature":
      min = scoutMinMax["temperature"].min;
      max = scoutMinMax["temperature"].max;
      break;
    case "water_balance":
      min = 0;
      max = 1;
      break;
    case "oxygen":
      min = 0;
      max = 21;
      break;
    default:
      break;
  }

  const getClassname = () => {
    const isBelowFreezing = isFreezingTemp(value, measuredVariable);
    if (measuredVariable === "voltage_battery" && !lastMeasurement && deviceType) {
      if (deviceType === "base") {
        if (value >= 3.8) {
          return `bg-gradient-l-battery1`
        } else if (3.8 > value && value >= 3.7) {
          return `bg-gradient-l-battery2`
        } else if (3.7 >= value && value >= 3.6) {
          return `bg-gradient-l-battery3`
        } else if (3.6 >= value && value >= 3.4) {
          return `bg-gradient-l-battery4`
        } else if (3.4 > value) {
          return `bg-gradient-l-battery5`
        }
      } else if (deviceType === "echo") {
        if (value >= 3.7) {
          return `bg-gradient-l-external1`
        } else if (value < 3.7) {
          return `bg-gradient-l-external2`
        }
      }
    } else if (measuredVariable === "voltage_external") {
      return `bg-gradient-l-external1`
    } else if (isBelowFreezing) {
      return `bg-gradient-l-moisture`
    } else {
      return className
    }

  }

  return (
    <div
      className={classNames("bg-gray-300 overflow-hidden font-medium w-full", {
        "rounded-md h-16": big,
        "rounded h-10": !big,
      })}
    >
      {lastMeasurement === null ? (
        <p className="flex items-center justify-center h-full text-gray-700">
          <FormattedMessage id="no_data" />
        </p>
      ) : ( value === undefined || value === null ) ? (
        <p className="flex items-center justify-center h-full text-gray-700">
          <FormattedMessage id={`no_${measuredVariable}`} />
        </p>
      ) : (value === 0 && measuredVariable === "moisture") ? (
        <p className="flex items-center justify-center h-full text-gray-700">
          <FormattedMessage id={`zero_${measuredVariable}`} />
        </p>
      ) : (
        <FormattedMeasurement
          imperial={imperial}
          variable={measuredVariable}
          value={value}
          >
          {
            ([number, unit, original]) => {
              if (measuredVariable === "water_balance") {
                // NOTE: We have to use `value` to avoid localization bugs that might convert `number` to
                // a localized number which uses different decimal separator conventions. Use `value`.
                //console.log(`number: ${number} value: ${value}`)
                number = (value).toFixed(2);
                if (number < 0.5 && number >= 0.25) {
                  className = "bg-gradient-l-water_balance2"
                } else if (number < 0.25) {
                  className = "bg-gradient-l-water_balance3"
                }
              } 
              
              else if (measuredVariable === "salinity" && value <= 0.1) {
                // Set the tooltip to "<0.1" for values less than or equal to 0.1.
                number = "<0.1";
                // Ensure the gauge displays the true value for non-nullish and non-negative values,
                // otherwise, set the value to 0.
                value = value >= 0 ? value : 0;
                // If the minimum value is unset(=nullish), set it to 0.
                min = min ?? 0;
                // If the maximum value is unset (=nullish) or 0, set it to 1, otherwise keep the current value.
                max = (max == null || max === 0) ? 1 : max;
            }
              

              return (
                <div
                  style={{
                    paddingRight: `${calculateGaugePadding(
                      measuredVariable === "temperature" || measuredVariable === "salinity"
                        ? parseFloat(value)
                        : parseFloat(number),
                      parseFloat(min),
                      parseFloat(max)
                    )}%`,
                    textShadow: "#0000004D 1px 1px 2px",
                    backgroundClip: "content-box",
                  }}
                  // FIXME: use _negative gradient in tailwind config
                  className={classNames(
                    getClassname(),
                    "h-full flex items-center select-none whitespace-no-wrap text-white",
                    { "rounded-r": !big, "rounded-r-md": big },
                    {
                      "text-lg": big,
                      "text-sm": !big,
                    }
                  )}
                >
                  <p className="ml-4">
                    {number} {unit}
                  </p>
                </div>
              );
            }
          }
        </FormattedMeasurement>
      )}
    </div>
  );
};

export default Gauge;
